<template>
	<div
		ref="ad"
		class="flex items-center justify-center w-full overflow-y-hidden rounded-lg"
		:style="componentDimensions"
	>
		<LoadingSpinner
			v-if="showSpinner"
			class="w-full h-full text-gray-300"
		/>
		<div v-else-if="showAd">
			<router-link
				v-if="isInternalLink"
				:to="{ path: adLink }"
			>
				<img
					:src="imageSrc"
					:height="adHeight"
					:width="adWidth"
					alt="Where's Weed"
				>
			</router-link>
			<a
				v-else
				:href="adLink"
				target="_blank"
			>
				<img
					:src="imageSrc"
					:height="adHeight"
					:width="adWidth"
					alt="Where's Weed"
				>
			</a>
		</div>
	</div>
</template>

<script async>
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import { CONTENT_SERVER } from '@/constants/index.js'
import { GetAd } from '@/gql/queries/ads.gql'
import { componentLevelGQLErrors } from '@/utils/error-handling.js'

const SITE_DOMAIN = 'https://wheresweed.com'

const ZONE_SIZES = {
	10: {
		width: 300,
		height: 250
	},
	11: {
		width: 650,
		height: 200
	}
}

export default {
	components: { LoadingSpinner },
	props: {
		zone: {
			type: Number,
			required: true
		},
		numberOfAdsOnPage: {
			type: Number,
			default: 1
		},
		randomizeResults: {
			type: Boolean,
			default: true
		},
		adIndex: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			loading: 0,
			observed: false,
			observer: null,
			skipQuery: true
		}
	},
	computed: {
		adWidth() {
			return ZONE_SIZES[this.zone]?.width
		},
		adHeight() {
			return ZONE_SIZES[this.zone]?.height
		},
		imageSrc() {
			let server = ''
			if (CONTENT_SERVER) server = CONTENT_SERVER
			return `${server}/pb/${this.currentAd.image}`
		},
		componentDimensions() {
			return {
				height: `${this.adHeight}px`,
				width: `${this.adWidth}px`
			}
		},
		showSpinner() {
			return this.loading || !this.observed
		},
		showAd() {
			return this.observed && !!this.currentAd
		},
		adLink() {
			return this.isInternalLink
				? this.currentAd?.link.split(SITE_DOMAIN)[1]
				: this.currentAd?.link
		},
		isInternalLink() {
			return this.currentAd?.link.includes(SITE_DOMAIN)
		},
		currentAd() {
			return this.ads?.data?.[this.adIndex]
		}
	},
	watch: {
		observed() {
			if (this.observed) {
				this.skipQuery = false
				this.$apollo.queries.ads.refetch()
			}
		}
	},
	mounted() {
		if (window && 'IntersectionObserver' in window) {
			this.observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						this.observed = true
						this.observer.disconnect()
					}
				})
			})
			this.observer.observe(this.$refs.ad)
		} else {
			this.observed = true
		}
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},
	apollo: {
		ads: {
			query: GetAd,
			update(data) {
				if (data.ads) {
					return {
						data: data.ads
					}
				}
			},
			variables() {
				return {
					zoneId: this.zone,
					count: this.numberOfAdsOnPage,
					randomizeResults: this.randomizeResults
				}
			},
			skip() {
				return this.skipQuery
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		}
	}
}
</script>
